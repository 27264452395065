.logo {
  float: left;
  height: 32px;
  width: 32px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  padding: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.interactiveLink:hover {
  background-color: #EEE;
}

.checkmark {
  display:inline-block;
  width: 22px;
  height:22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem {
  position: absolute;
  width:3px;
  height:9px;
  background-color:#000;
  left:11px;
  top:6px;
}

.checkmark_kick {
  position: absolute;
  width:3px;
  height:3px;
  background-color:#000;
  left:8px;
  top:12px;
}